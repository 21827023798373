.j-endorse-button-text {
    display: inline-block;
    width: auto;
}
.j-profile-tile-section {
    /* position:relative; */
}
header.enhanced-profile-page-header {
    /* margin-top: -70px; */
    margin-bottom: 20px;
    position: relative;
    background: #fff;
    /* overflow: hidden; */
    border-bottom: solid 1px #dadada;
    box-sizing: border-box;
    width: 100%;
}

.enhanced-profile-header-container {
    display: inline-block;
    overflow: visible;
    min-height: 140px;
    width: 100%;
}
.tooltip-switch .tooltip {
    word-break: break-word;
}
header.enhanced-profile-page-header a.j-avatar {
    float: left;
    width: 128px;
    height: 128px;
    border-radius: 128px;
    overflow: hidden;
    margin-left: 20px;
    margin-top: -50px;
    margin-bottom: 60px;
    display: block;
    border: 5px solid white;
    background:white;
    position:relative;
}
header.enhanced-profile-page-header a.j-avatar i.icon {
    display:none;
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    top: 0;
    left: 0;
    line-height: 128px;
    color:black;
    text-decoration:none;
}
header.enhanced-profile-page-header a.j-avatar.self:hover i.icon {
    display:block;
}
header.enhanced-profile-page-header a.j-avatar img{    
    transition: 0.3s;
}
header.enhanced-profile-page-header a.j-avatar.self:hover img{    
    opacity:0.3;
}
.background-image-container {
    width: 100%;
}
.background-image-container {
    background-image: url(../images/header.jpg);
    background-position: center center;
    background-size: cover;
    content: "";
    display: block;
}
.background-image-hover .background-image-container {
    opacity: 0.3;
}
.background-image-hover #enhanced-profile-header-background-options {
    display: block;
}
.enhanced-profile-page-header .j-pop {
    width: 390px;
}
.user-header-background {
    width: 390px;
    margin-bottom: 5px;
    padding: 0 !important;
}
.user-header-background.j-selected {
    width: 388px;
    border: 1px solid;
}
#j-preset-header-background a {
    background-position: center center;
    background-size: cover;
}
.current-workinghours {
    display: inline-block;
    overflow: hidden;
    max-width: 250px;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
}
#enhanced-profile-header-name {
    padding: 24px 25px 0;
    float: left;
    /* overflow: auto; */
    color:#373737;
    margin-top: -65px;
    /* background: rgba(255,255,255,0.2); */
    /* padding-left: 200px; */
    border-radius: 0 20px 0 0;
    display:block;
    float:left;
    max-width:calc(100% - 240px);
}
#enhanced-profile-header-details {
    height: auto;
    padding: 15px 20px 0;
    float: left;
    /* overflow: auto; */
    color: #373737;
    /* background: rgba(255,255,255,0.2); */
    /* padding-left: 200px; */
    border-radius: 0 20px 0 0;
    display: block;
    max-width: calc(100% - 460px);
    margin-bottom: 45px;
}
#enhanced-profile-header-options {
    width: auto;
    /* height: 140px; */
    /* padding: 24px 25px 0; */
    float: right;
    display:block;
    /* overflow: auto; */
    color:#373737;
    margin-top: 15px;
    /* background: rgba(255,255,255,0.2); */
    /* padding: 0 40px; */
    text-align: right;
    font-weight: bold;
    /* border-radius: 0 20px 0 0; */
    position:relative;
    margin-bottom: 45px;
    max-width: 300px;
}
#enhanced-profile-header-background-options {
    width: 100px;
    float: right;
    display: none;
    color: #373737;
    top: 10px;
    right: 50px;
    padding: 0 40px;
    text-align: center;
    font-weight: bold;
    position:absolute;
}
#enhanced-profile-header-background-options label.edit-header-background-switch {
    cursor: pointer;
}
#enhanced-profile-header-options .sub-header-value {
    word-break: break-word;
}
#enhanced-profile-header-options .sub-header-value, #enhanced-profile-header-background-options .header-background {
    padding: 5px 22px;
    display:block;
    position:relative;
}
#enhanced-profile-header-options .sub-header-value, 
#enhanced-profile-header-background-options .header-background {
    cursor:pointer;
    padding: 5px 12px;
}
#enhanced-profile-header-options .j-pop {
    display: none;
    position: absolute;
    right: 0;
    text-align: left;
    top: 30px;
}

#enhanced-profile-header-options .j-pop .j-form {
padding:10px;
display:block !important;
}
#enhanced-profile-header-options .j-pop input {
    margin-bottom:10px;
}
#enhanced-profile-header-background-options .j-pop {
    display: none;
    position: absolute;
    right: 0;
    text-align: left;
    top: 30px;
    word-break: break-word;
}
#enhanced-profile-header-background-options .j-file-upload .j-complete {
    word-break: break-all;
}
#enhanced-profile-header-background-options .j-file-upload input[type="file"] {
    width: 100%;
}
#enhanced-profile-header-name h1 {
    color: black;
    text-shadow: 0 0 5px white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
}
// IPCST-96698: truncate long display names
#enhanced-profile-header-name span.displayName {
    display: inline-block;
    max-width: 800px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#enhanced-profile-header-details ul {
    list-style:none;
    float: left;
    margin-right: 40px;
}

#enhanced-profile-header-details ul li {
    /* width: 45%; */
    /* float: left; */
    padding: 5px;
    font-weight:bold;
    position: relative;
}

#enhanced-profile-header-details li > span.font-color-meta-light, #enhanced-profile-header-details li > span.font-color-white {
    display:block;
    position: absolute;
    left: -5px;
    bottom: 26px;
    background: white;
    height:0;
    overflow:hidden;
    width: auto;
    padding: 0 20px 0 10px;
    line-height: 25px;
    border-radius: 4px;
    transition: height 0.2s linear;
    color: #999;
    white-space: nowrap;
    z-index: 10;
}

#enhanced-profile-header-details li:hover > span.font-color-meta-light, #enhanced-profile-header-details li:hover > span.font-color-white {
    display: block;
    height: 20px;
}

#enhanced-profile-header-details .jive-orgchart-recenter
{
    display:none;
}

.enhanced-profile-page-header #j-profile-header-avatar-stats {
    float:right;
    border-left:none !important;
    margin: 70px 20px 0;
    width: auto;
}

.enhanced-profile-profile-points {
    color:#000;
    width: 200px;
    overflow:hidden;
    border-radius: 40px;
    height: 20px;
    text-align:center;
    border: 1px solid #0077B5;
    position:relative;
    background: #fff;
    cursor:pointer;
    margin:5px 5px 0;
    float:right;
}
.enhanced-profile-progress {
    position:absolute;
    background: #0077B5;
    height: 100%;
}
.enhanced-profile-points {
    position:absolute;
    width: 100%;
    line-height: 20px;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 13px;
} 
.j-profile-nav {
    border:none; 
}
.enhanced-profile-page-header, .j-usercard-actions {
    a.contact-icon {
        padding: 2px;
        color: white;
        background: #0077B5;
        margin: 0 10px 0 0;
        width: 25px;
        height: 25px;
        display: inline-block;
        text-align: center;
        line-height: 28px;
        border-radius: 4px;
        float: left;
        text-decoration: none !important;
        font-size: 18px;
    }

    a.contact-icon:hover {
        box-shadow: inset 0 0 10px 10px rgba(0,0,0,0.2);
    }
    a.contact-icon.twitter {
        background: #1DA1F2;
    }
    a.contact-icon.message {
        background: #FF7900;
        font-size:16px;
    }
    .j-card-button {
        margin-right:0;
        float: left;
    }
}
.j-profile-tile-section .enhanced-profile-sub-header {
    position: relative;
    float: left;
    width: 100%;
    margin: 0 0 20px 0;
}
.enhanced-profile-tile section {
    padding: 10px;
}
.extended-fields {
    margin: 0 0 0 0;
    position: relative;
    float: left;
    background: #fff;
    border-radius: 5px;
}
header .visibility-switch.control-icon, .edit-switch.control-icon, .bio-edit-switch.control-icon, .delete-switch.control-icon, .tooltip-switch.control-icon {
    float: right;
    padding: 3px 0 0 10px;
    position:relative;
}
header .visibility-switch.control-icon i, .edit-switch.control-icon i, .bio-edit-switch.control-icon i, .delete-switch.control-icon i, .tooltip-switch.control-icon i{
    cursor: pointer;
    color: #666;
    text-decoration: none !important;
}

.visibility-block {
    transition: 0.3s;
}
.visibility-block.disabled {
    opacity: 0.4;
}
.visibility-block .hidden-message{
    display:none;
    position: relative;
    top: 4px;
    left: 10px;
}
.visibility-block.disabled .hidden-message{
    display:inline;
}
.bio-edit-switch.control-icon {
    padding:3px 0 5px 10px;
}
.j-pop.tooltip {
    width: 240px;
    left: auto;
    right: -11px;
    top: 30px;
}
.j-pop.tooltip .j-pop-desc h2 {
    color:black;
}

.j-pop.tooltip .j-pop-main {
    padding:10px;
}
.j-pop.tooltip .j-pop-main span {
    margin:0;
}


.experience-component section {
    padding:0;
}
.experience-component #add-item {
    float:right;
    margin: 0 0 20px 0;
}

.experience-component section.profile-data,
.experience-component section.form-template {
    padding:20px 15px;
}
.experience-component .form-template {
    display:none;
}
.experience-component .placeholder{
    color: #999;
    text-align: center;
    padding: 30px;
}
.experience-component .form-template input,
.experience-component .form-template textarea {
    margin-bottom: 10px;
}

#ui-datepicker-div {display: none;}
label.inline {
    display: inline;
    font-weight: normal;
}
input#end:disabled {
    opacity: 0.4;
}
#present-job {
    float:none;
    margin-left:40px;
    margin-right:0;
}

.enhanced-profile-tile header h3 {
    float: left;
    font-weight: bold !important;
    font-size: 18px !important;

}
.enhanced-profile-data {
    margin: 20px;
}
.enhanced-profile-data li {
    display: none;
}
.j-profile-tile-section .enhanced-profile-sub-header span.sub-header-title {
    text-transform: uppercase;
    font-weight: bold;
}
.j-profile-tile-section .enhanced-profile-sub-header span.sub-header-value {
    margin: 0 12px 0 10px;
}
.j-profile-tile-section .content-activity .j-profile-activity {
    margin: 0;
}
.j-profile-tile-section .j-profile-left-column {
    width: 68%;
    margin: 0 0 0 0;
    position: relative;
    float: left;
    border-radius: 5px;
}
.j-profile-tile-section .j-profile-left-column .left-column-section {
    width: 100%;
    background: #fff;
    margin: 0 0 40px 0;
    position: relative;
    overflow:auto;
    float: left;
}
.j-profile-tile-section .j-profile-left-column .experience-and-skills-container {
    width: 60%;
    margin: 0 0 0 0;
    position: relative;
    float: left;
}
.j-profile-tile-section .j-profile-left-column .enhanced-profile-user-blog {
    margin: 0 0 20px 0;
}
.enhanced-profile-blog-posts-show-more {
    margin: 0px 0px 20px 15px;
    cursor: pointer;
}
.j-profile-tile-section .j-profile-left-column .jive-icon-huge {
    margin-top:-3px;
}
.j-profile-tile-section .j-profile-left-column .enhanced-profile-activity-container {
    width: 100%;
    margin: 0 0 0 0;
    position: relative;
    float: left;
}
.j-profile-tile-section .j-profile-left-column .enhanced-profile-expertise-container {
    width: 100%;
    margin: 0 0 0 0;
    position: relative;
    float: left;
}
.left-column-section .experience-entry .display {
    margin-bottom:20px;
}
.experience-entry {
    word-break: break-word;
}
.left-column-section .experience-sub-header {
    display: block;
    margin: 12px 0;
}
.left-column-section .experience-description {
    display: block;
    margin: 12px 0 2px 0;
    color: #696969;
/*     white-space: nowrap; */
    transition:0.3s;
    max-height:136em;
    white-space: unset;
    text-overflow: unset;
    overflow: hidden;
}
.left-column-section .experience-description.short {
    max-height:19px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display:block;
}

.j-profile-tile-section .j-profile-left-column .left-column-section .j-extended-profile {
    width: 40%;
    min-height: 400px;
}

.j-expertise footer .j-btn-global.j-btn-half {
    width: auto;
}

.j-expertise footer .j-btn-global.j-btn-half:last-child {
    white-space: nowrap;
}

.enhanced-profile-data-show-more, .enhanced-profile-data-hide-more, .experience-show-more, .experience-hide-more, .enhanced-profile-connections-show-more {
    text-align:left;
    color: #999;
    display:block;
    cursor:pointer;

}
.enhanced-profile-connections-show-all {
    margin: 0 0 10px 20px;
}
.connections-show-all-container a, .connections-show-all-container a:link {
    color: #999;
}
.experience-hide-more {
    display:none;
}
.enhanced-profile-data-hide-more {
    display:none;
}
.j-profile-tile-section .j-profile-left-column .left-column-section .experience-component {
    min-height: 300px;
    width: 100%;
    margin: 0 0 0 0;
    position: relative;
    float: left;
}
.j-profile-tile-section section.profile-data.biography {
    border-bottom: 1px solid #e3e3e3;
}
.j-profile-tile-section .j-profile-left-column .left-column-section .expertise-component {
    height: 300px;
    width: 100%;
    margin: 0 0 0 0;
    position: relative;
    float: left;
}
.j-profile-tile-section .j-profile-left-column .left-column-section .skills-and-expertise-profile-tile {
    width: 98%;
    margin: 0 0 0 2%;
    position: relative;
    float: left;
    background: #fff;
    border-radius: 5px;
}
.j-profile-tile-section .j-profile-right-column {
    width: 30%;
    margin: 0 0 0 2%;
    position: relative;
    float: right;
    border-radius: 5px;
}
.j-profile-tile-section .j-profile-right-column .profile-tile {
    width: 96%;
    margin: 0 0 20px 2%;
    position: relative!important;
    float: left;
    background: #fff;
    border-radius: 5px;
}
.j-profile-tile-section .j-profile-right-column .enhanced-status-update {
    width: 96%;
    margin: 0 0 20px 2%;
    position: relative;
    float: left;
    background: #fff;
    border-radius: 5px;
}
.j-profile-tile-section .j-profile-right-column .j-connections-orgchart {
    width: 96%;
    margin: 0 0 20px 2%;
    position: relative;
    float: left;
    background: #fff;
    border-radius: 5px;
}
.j-profile-tile-section .j-profile-right-column .j-expertise-tile {
    width: 96%;
    margin: 0 0 20px 2%;
    position: relative;
    float: left;
    background: #fff;
    border-radius: 5px;
}
.j-profile-tile-section .j-profile-right-column .connections {
    width: 96%;
    margin: 0 0 20px 2%;
    position: relative;
    float: left;
    background: #fff;
    border-radius: 5px;
}
.j-profile-tile-section .j-profile-right-column .people {
    width: 96%;
    margin: 0 0 20px 2%;
    position: relative;
    float: left;
    background: #fff;
    border-radius: 5px;
}
.j-profile-tile-section .j-profile-right-column .place-activity {
    width: 96%;
    margin: 0 0 20px 2%;
    position: relative;
    float: left;
    background: #fff;
    border-radius: 5px;
}
.j-profile-tile-section .j-profile-right-column .place-activity .activity-place {
    margin: 20px;
}
.j-profile-tile-section .j-profile-right-column .enhanced-status-update .status-update-text {
    float: left;
    padding: 30px;
    word-break: break-word;
    -ms-word-break: break-all;
}
.j-profile-tile-section .j-profile-right-column .enhanced-status-update .status-update-text a:hover {
    text-decoration:none;
}
.j-profile-tile-section .j-profile-right-column .enhanced-status-update .status-update-text .jive-rendered-content{
    display: inline;
    line-height: 30px;
}

.j-profile-tile-section .j-profile-right-column .enhanced-status-update .status-update-text .status-update-quote-mark {
    font-weight: 700;
    font-size: 70px;
    font-size: 4em;
    color:#ddd;
    text-decoration:none !important;
    position: relative;
    top: 17px;
    right: 13px;
    line-height: 0px;
    margin: 0;
    /* float: right; */
}
.j-profile-tile-section .j-profile-right-column .enhanced-status-update .status-update-text .status-update-quote-mark:last-child {
    float: right;
    right: -20px;
}
.j-profile-tile-section .j-profile-right-column .enhanced-status-update .update-link {
    float: left;
    padding: 30px;
}
.j-profile-tile-section .profile-tile {
    background:#fff;
    border-radius:0;
    min-height:330px;
}
.j-profile-tile-section .face {
  /* padding: 18px 15px 0; */
  padding: 0;
}
.j-profile-tile-section .j-tile-header {
  padding: 18px 15px 0 !important;
  border-radius: 0;
}

.profile-data.card-back.face {
  top: 0;
  left: 0;
  color: #999;
}

 .loading::before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
}

 .loading::before {
    border-top: 5px solid rgba(51,51,51,0.1);
    border-right: 5px solid rgba(51,51,51,0.1);
    border-bottom: 5px solid rgba(51,51,51,0.1);
    border-left: 5px solid #666;
    overflow: hidden;
    content: '';
    display: block;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    -ms-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: circle 0.85s infinite linear;
    animation: circle 0.85s infinite linear;
}

 .loading::before {
    border-left: 5px solid #666;
}
#enhanced-profile-profile-points-outer {
    float: right;
    margin-right: 7px;
}
#enhanced-profile-gamification-eye-container #eye-container {
    float: right;
    margin: 4px 16px 0 0;
}

.enhanced-profile-blog-post-entry {
    margin: 12px 0 20px 15px;
}
.enhanced-profile-blog-post-entry h7 {
    display: block;
    margin-left: 3px;
    margin-bottom: 5px;
}
.enhanced-profile-blog-post-entry .blog-post-body-snippet {
    margin: 10px 3px;
}

/*Mobile adjustments */
@media only screen and (max-width:40em) {
    .background-image-hover #enhanced-profile-header-background-options {
        display: none;
    }

    .enhanced-profile-header-container {
        display: block;
    }
    #enhanced-profile-header-details ul {
        max-width: calc(100% - 40px);
    }
    .j-profile-tile-section .j-profile-right-column .enhanced-status-update .status-update-text {
        float: none;
    }
    header.enhanced-profile-page-header a.j-avatar {
        float:none;
        margin: -50px auto 60px;
    }
    #enhanced-profile-header-name{
        max-width:100%;
    }
    #enhanced-profile-header-details{
        max-width:100%;
    }

    #enhanced-profile-header-details li>span.font-color-meta-light, 
    #enhanced-profile-header-details li>span.font-color-white {
        display: block;
        height: 26px;
        position: static;
        padding:0;
    }
    #j-profile-header-actions {
        margin: 0;
        /* float: none; */
        /* overflow: auto; */
        width: 100%;
        /* display: block; */
        text-align: center;
    }
    #enhanced-profile-header-options .j-pop {
        position: static;
        border: 1px solid #ccc;
        margin-top: 10px;
        ul li a {
            padding: 15px 10px;
        }
    }
    header .visibility-switch.control-icon, .edit-switch.control-icon, .bio-edit-switch.control-icon, .delete-switch.control-icon, .tooltip-switch.control-icon {
        padding: 5px 0px 5px;
        margin-left: 10px;
        position: relative;
    }
    #enhanced-profile-header-options {
        float:none;
        overflow: auto;
        clear:both;
        text-align:center;
        max-width: 100%;
    }
    #enhanced-profile-profile-points-outer {
        float:none;
    }
    .enhanced-profile-profile-points {
        display:inline-block;
        float:none;
    }

    #enhanced-profile-gamification-eye-container #eye-container {
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .j-profile-nav {
        position:static !important;
        margin-top:20px;
    }
    .enhanced-profile-page-header a.contact-icon, .j-usercard-actions a.contact-icon {
        float:none;
        margin-top: 7px;
    }
    #j-profile-header-actions .j-profile-sendmessage, #j-profile-header-actions .j-follow-user-link {
        width:auto !important;
    }
    #j-profile-header-actions .j-btn-global {
        padding: 0 20px;
    }
    .j-profile-tile-section .j-profile-left-column .left-column-section .j-extended-profile {
        width: 100%;
        min-height: auto;
    }
    .j-profile-tile-section .j-profile-left-column .left-column-section .experience-component {
        min-height: auto;
    }
    .j-profile-tile-section .j-profile-left-column .experience-and-skills-container {
        width: 100%;
    }
    .enhanced-profile-tile {
        border-radius:0 !important;
        border-bottom: 1px solid #ccc !important;
    }
    .j-pop.tooltip {
        padding: 10px;
        position: absolute;
        background: #fff;
        box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.3);
        border-radius: 3px;
    }
    /*Changes done for OBS-488*/
    body.j-body-profile .j-profile-tile-section .j-connections-orgchart footer .j-btn-global {
        display: block;
    }

    /*OBS 484*/
    #enhanced-profile-header-name h1 {
        white-space: normal;
        word-break: break-word;
    }

    /*Changes done for OBS-619 and OBS-487*/
    body.j-body-profile .j-profile-tile-section .j-expertise footer .j-btn-global {
        display: block;
    }

    body.j-body-profile .j-expertise .card .face {
        width: 100%;
        height: 100%;
    }

    body.j-body-profile .j-expertise .card .card-front section ul,
    body.j-body-profile .j-expertise .card .card-front footer, {
        width: 90%;
        margin-left: 5%;
    }

    .j-expertise .card.flipped .card-back {
        display: block !important;
    }

    .j-expertise footer .j-btn-global.j-btn-half {
        float: left;
        width: 50%;
    }

    .ui-datepicker-title {
        max-width: 140px;
    }

    .j-profile-addon-actions {
        display: inline !important;
        float: none;
    }

    body.j-body-profile #j-profile-header-actions a.j-addOn-button {
        display: inline;
    }
}

.j-expertise footer .j-btn-global.j-btn-half {
    width: 47%;
    margin-right: 5px;
}
.j-endorse-control.j-endorsed-control {
    top: -23px;
    position: relative;
}
.j-endorse-control .j-endorse-icon {
    margin-top: -21px;
}
.j-endorse-control .j-unendorse-icon {
    margin-top: -21px;
}
.j-endorse-button-text {
    box-shadow: none;
}
